import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import ModalView from "./Views/ModalView";
import "../../../../src/index.scss";
import CustomPopper from "./Views/EventPopper";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DimmingDots from "./Views/LoaderCustom";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InfoIcon from "@mui/icons-material/Info";
import BalanceIcon from "@mui/icons-material/Balance";
import { MdGroups } from "react-icons/md";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { Modal } from "react-bootstrap";
import GroupsIcon from "@mui/icons-material/Groups";
import { connect } from "react-redux";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import BookingModal from "./Views/BookingModal";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Drawer,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Slide,
  Tooltip,
  Typography,
  useMediaQuery,
  Badge,
  Switch,
  Divider,
  TextField,
  CircularProgress,
  Stack,
  Checkbox,
} from "@mui/material";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  getBookingDataNoauth,
  getReservationInfoDjango,
} from "./api/ReservationApi";
import {
  channelLogoMap,
  dateFormat,
  getSlotType,
  multiOptions,
  navigationParamsForCalendar,
  views,
} from "./constants/Calendar-constants";
import { BookingDataModel } from "./models/BookingDataModel";
import moment from "moment";
import EditModalView from "./Views/EditModalView";
import ExtendModalView from "./Views/ExtendModalView";
import DragModalView from "./Views/DragModalView";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  FiberManualRecord,
  Lock,
  MoreVert,
} from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { shallowEqual, useSelector } from "react-redux";
import { isNull, isUndefined } from "lodash";
import { getAllNonHiddenRoomTypes } from "../InventoryCalendar/api/InventoryApi";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MultipleBookingView from "./Views/MultipleBookingView";
import "../../../index.scss";
import "@fullcalendar/resource-timeline/main.min.css";
import UnassignedView from "./Views/UnassignedView";
import Folios from "../Folios/Folios";
import UnblockView from "./Views/UnblockView";
import { getAllRoomTypessWithHotelId } from "../RevenueManagement/RatePlans/apis/RatePlanApis";
import { Dropdown } from "react-bootstrap";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import NewEnquiry from "./Views/NewEnquiry";
import BlockRoom from "./Views/BlockRoom";
import Walkin from "./Views/Walkin";

import { getAllImagesAndUrl } from "../BookingDashboard/apis/BrandingModalApi";
import { useHistory } from "react-router-dom";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import axios from "axios";
import GroupModalView from "./GroupModalView";
import GroupEditModalView from "./GroupEditModalView";
import GroupExtendModalView from "./models/GroupExtendModalView";
import GroupDragModalView from "./models/GroupDragModalView";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: "95%",
  },
  drawers: {
    flexShrink: 0,
    width: "55%",
  },
  drawerPaper: {
    width: "95%",
  },
  drawerPapers: {
    width: "55%",
  },
}));

const NewGroupTimeLine = ({
  hotelType,
  selectedLanguage,
  hotelId,
  hotelCurrency,
  slotType,
  setToday,
  today,
  hotelCountry,
  forward,
  backward,
  setforward,
  setbackward,
  expanded,
  occupancyData,
  getAllData,
}) => {
  const { t } = useTranslation();

  const getHotelDetails = (hotelId, date) => {
    const hotelsForDate =
      occupancyData[moment(date).format("DD-MM-YYYY")] || [];
    return (
      hotelsForDate.find((hotel) => hotel.hotelId === hotelId) || {
        occRatio: "",
        price: "",
      }
    );
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const history = useHistory();

  useEffect(() => {
    if (forward && expanded) {
      let tempData = getParamsForView("add");
      fetchBookingDataWithParams(tempData);
      calendarApi.next();
      setShowUnassigned(false);
      setforward(false);
    }
  }, [forward]);

  useEffect(() => {
    if (backward && expanded) {
      let tempData = getParamsForView("subtract");
      fetchBookingDataWithParams(tempData);
      calendarApi.prev();
      setShowUnassigned(false);
      setbackward(false);
    }
  }, [backward]);

  useEffect(() => {
    if (expanded && calendarApi) {
      calendarApi.gotoDate(today);
      let tempData = getParamsForViewForGroup("date");
      fetchBookingDataWithParams(tempData);
    }
  }, [today]);

  const hotelSlot = getSlotType(slotType);
  const Noonslot = { day: 0, hour: 8 };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const calendarRef = useRef(null);
  const navigationParams = Object.assign(navigationParamsForCalendar);
  navigationParams.hotelId = hotelId;
  let [actionType, setActionType] = useState("");
  let [showModal, setShowModal] = useState({});
  let [show, setShow] = useState(false);
  let [initialData, setInitialData] = useState(new BookingDataModel({}));
  let [calendarApi, setCalendarApi] = useState(null);
  let [loading, setLoading] = useState(false);
  // let [snackbar, setSnackbar] = useState(false);
  const [rooms, setRooms] = useState([
    { text: "Available Rooms", id: "availableRooms" },
    { text: "Blocked Rooms", id: "blockedRooms" },
    { text: "Dirty Rooms", id: "dirtyRooms" },
    { text: "Clean Rooms", id: "cleanRooms" },
    { text: "Occupied Rooms", id: "bookedRooms" },
    { text: "All", id: "All" },
    { text: "Unselect", id: "Unselect" },
  ]);
  const [selectedRoom, setSelectedRoom] = useState([]);

  const [roomNumbers, setRoomNumbers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(Boolean(anchorEl));
  const [multiOption, setMultiOption] = useState("");
  const onOpenEditCustomerModal = () => setOpenEditCustomerModal(true);
  const onCloseEditCustomerModal = () => setOpenEditCustomerModal(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const onOpenServiceModal = () => setOpenServiceModal(true);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const windowWidth = window.screen.innerWidth;
  const [state, setState] = useState({ right: false });
  const [showUnassigned, setShowUnassigned] = useState(false);
  const [unassignedDate, setUnassignedDate] = useState(new Date());
  const [originalAllRoomInfo, setOriginalAllRoomInfo] = useState([]);
  const [showRoomName, setshowRoomName] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dirtyRoomId, setdirtyRoomId] = useState("");
  const [openBookingModal, setopenBookingModal] = useState(false);
  const [bookingData, setbookingData] = useState({});
  const [reservationData, setreservationData] = useState({});
  const envType = process.env.REACT_APP_ENV;
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  // Magic link modal
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(async () => {
    if (Object.entries(bookingData).length > 0) {
      const data = await getReservationInfoDjango(bookingData.reservationId);
      setreservationData(data);
      setMagicLinkMail(data.user_email);
      setMagicLinkPhone(data.user_contact);
      setUpiAmt(data.balance_due);
      setAmount(data.balance_due);
      setOfflineCardAmt(data.balance_due);
      setBankTransferModeAmt(data.balance_due);
      setOtherModeAmt(data.balance_due);
      setOfflineCheckAmt(data.balance_due);
    }
  }, [bookingData]);

  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
  }, []);

  const whitlableArrMap = [
    "STAYFLEXI_OD",
    "STAYFLEXI_OM",
    "STAYFLEXI MOBILE",
    "STAYFLEXI_GHA",
    "STAYFLEXI_BE",
    "STAYFLEXI_MOBILE",
  ];

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState("");
  const [magicLinkPhone, setMagicLinkPhone] = useState("");
  // send magic link
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  function handleSendMagicLink() {
    if (!validateEmail(magicLinkMail)) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail.includes("example.com")) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error(
        `${t("Empty email or phone, please enter valid email and phone.")}`
      );
      setMagicLinkLoading(false);
      return;
    }
    setMagicLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: bookingData.booking_id,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success(`${t("Magic link sent successfully.")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .then((res) => setMagicLinkMail(reservationData.user_email))
      .then((res) => setMagicLinkPhone(reservationData.user_contact))
      .catch((err) => toast.error(err))
      .catch((err) => setMagicLinkLoading(false));
  }

  const openTheBookingModal = (resp) => {
    setbookingData(resp);
    setopenBookingModal(true);
  };
  const closeTheBookingModal = () => setopenBookingModal(false);

  const classes = useStyles();
  const changeDateTime = (data) => {
    showModal.event.setDates(data.start, data.end);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const moveToNewRoom = (id) => {
    showModal.event.setResources([id]);
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  const getStartEndDates = () => {
    let title = calendarApi.view.type;
    let currentDate = calendarApi.getDate();
    let endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + (views[title] - 1));
    return { start: currentDate, end: endDate };
  };

  const lockEvent = (lockType) => {
    showModal.event.setExtendedProp("locked_status", lockType);
    handleAddEventSidebar();
  };

  const removeEvent = () => {
    showModal.event.remove();
    if (calendarApi !== null) {
      calendarApi.refetchEvents();
    }
    handleAddEventSidebar();
    fetchBookingDataWithoutLoad();
  };

  // Creating a new event when selecting in a empty space
  const handleDateSelect = (data) => {
    if (data.event.extendedProps.status === "COMBO") return;
    if (data.event.extendedProps.status === "BLOCKED")
      setActionType("edit-blocked");
    else setActionType("edit");
    setScrollPosition(window.pageYOffset);
    setShowModal(data);
    setShow(true);
  };

  const handleCleanRoom = () => {
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-clean?${hotelId}`,
        {
          roomIds: [`${dirtyRoomId}`],
          staffId: "",
        }
      )
      .then((res) => {
        toast.success(`${t("Successfully cleaned room")}`);
        fetchBookingDataWithoutLoad();
        handleClose();
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        fetchBookingDataWithoutLoad();
        handleClose();
      });
  };

  // When event position changes for Drag and drop and extension
  const handleDragStop = (data) => {
    if (
      data.event.getResources()[0].id !== data.oldEvent.getResources()[0].id &&
      data.oldEvent.startStr !== data.event.startStr &&
      data.oldEvent.endStr !== data.event.endStr
    ) {
      data.revert();
      return;
    }
    setShowModal(data);
    setShow(true);
  };

  // Closing the modal for creating new event
  const handleAddEventSidebar = () => {
    setShow(!show);
    setShowModal(null);

    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 700);
  };

  // Reverting the changes when drag and drop is cancelled
  const modify = () => {
    showModal.revert();
    handleAddEventSidebar();
  };

  // Opening a modal for new event in the empty boxes
  const addNew = (data) => {
    setActionType("add");
    setScrollPosition(window.pageYOffset);
    setShowModal(data);
    setShow(true);
  };

  // Adding the new event to the calendar
  const setNewData = (obj, val) => {
    if (!val) {
      calendarApi.addEvent(obj);
      if (calendarApi !== null) {
        calendarApi.refetchEvents();
      }
    }

    // if (val === "success") {
    //   setSnackbar(true);
    // }
    handleAddEventSidebar();
  };

  const checkForEmptyCalendar = () => {
    if (isNull(calendarApi)) {
      return false;
    }
    if (isUndefined(calendarApi.view)) {
      return false;
    }
    return true;
  };

  const fetchBookingDataWithoutLoad = async () => {
    const params = getParamsForView("any");
    initialData.roomsData = [];
    initialData.bookingData = [];

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }
    if (params?.roomTypes !== undefined && params?.roomTypes !== null) {
      params.roomTypes = params?.roomTypes.filter(
        (item) =>
          item !== "blockedRooms" &&
          item !== "availableRooms" &&
          item !== "dirtyRooms" &&
          item !== "cleanRooms" &&
          item !== "bookedRooms"
      );
    } else {
      params.roomTypes = null;
    }

    const data = await getBookingDataNoauth(params, history);
    const resp = new BookingDataModel(
      data,
      params.viewType,
      slotType === "NOON" ? "THREE_SLOT" : slotType
    );
    calendarApi.removeAllEvents();
    setRoomNumbers(resp.roomsData);
    setInitialData(resp);
    // getAllData();
  };

  // useEffect(() => {
  //   fetchBookingDataWithoutLoad()
  // }, [])

  //Fix

  const reloadData = () => {
    let tempData = getParamsForView("month");
    fetchBookingDataWithParams(tempData, "month");
  };

  const fetchBookingDataWithParams = async (params, type) => {
    setLoading(true);
    calendarApi.removeAllEvents();
    initialData.roomsData = [];
    initialData.bookingData = [];

    if (params?.roomTypes?.includes("blockedRooms")) {
      params.blockedRooms = true;
    } else if (params?.roomTypes?.includes("availableRooms")) {
      params.availableRooms = true;
    } else if (params?.roomTypes?.includes("dirtyRooms")) {
      params.dirtyRooms = true;
    } else if (params?.roomTypes?.includes("cleanRooms")) {
      params.cleanRooms = true;
    } else if (params?.roomTypes?.includes("bookedRooms")) {
      params.bookedRooms = true;
    }

    if (params?.roomTypes !== null && params?.roomTypes !== undefined) {
      params.roomTypes =
        params?.roomTypes.length > 0 &&
        params?.roomTypes.filter(
          (item) =>
            item !== "blockedRooms" &&
            item !== "availableRooms" &&
            item !== "dirtyRooms" &&
            item !== "cleanRooms" &&
            item !== "bookedRooms"
        );
    } else {
      params.roomTypes = null;
    }
    if (
      params?.roomTypes !== null &&
      params?.roomTypes !== undefined &&
      params?.roomTypes === false
    ) {
      params.roomTypes = null;
    }

    const data = await getBookingDataNoauth(params, history);
    let resp;
    if (type === "month" || sessionStorage.getItem("types") === "month") {
      resp = new BookingDataModel(data, params.viewType, slotType);
    } else {
      resp = new BookingDataModel(
        data,
        params.viewType,
        slotType === "NOON" ? "THREE_SLOT" : slotType
      );
    }
    setRoomNumbers(resp.roomsData);
    setInitialData(resp);
    setLoading(false);
    // getAllData();
  };

  const fetchBookingData = async () => {
    try {
      setLoading(true);
      let temp = Object.assign(navigationParams);
      temp.startDate =
        hotelCurrency === "USD"
          ? moment(new Date(new Date().getTime() - 86400000))
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat)
          : moment(new Date())
              .set({ hour: 0, minute: 0, second: 0 })
              .format(dateFormat);

      if (temp?.roomTypes?.includes("blockedRooms")) {
        temp.blockedRooms = true;
      } else if (temp?.roomTypes?.includes("availableRooms")) {
        temp.availableRooms = true;
      } else if (temp?.roomTypes?.includes("dirtyRooms")) {
        temp.dirtyRooms = true;
      } else if (temp?.roomTypes?.includes("cleanRooms")) {
        temp.cleanRooms = true;
      } else if (temp?.roomTypes?.includes("bookedRooms")) {
        temp.bookedRooms = true;
      }

      if (temp?.roomTypes !== null && temp?.roomTypes !== undefined) {
        temp.roomTypes =
          temp?.roomTypes.length > 0 &&
          temp?.roomTypes.filter(
            (item) =>
              item !== "blockedRooms" &&
              item !== "availableRooms" &&
              item !== "dirtyRooms" &&
              item !== "cleanRooms" &&
              item !== "bookedRooms"
          );
      } else {
        temp.roomTypes = null;
      }

      const data = await getBookingDataNoauth(temp, history);
      const resp = new BookingDataModel(
        data,
        "resourceTimelineWeek",
        slotType === "NOON" ? "THREE_SLOT" : slotType
      );
      setRoomNumbers(resp.roomsData);
      setOriginalAllRoomInfo(data.allRoomReservations.singleRoomReservations);

      setInitialData(resp);
      setLoading(false);
      // getAllData();
    } catch (er) {
      console.log(er);
    }
  };

  const getParamsForView = (val) => {
    let title = calendarApi?.view?.type;

    let currentDate = calendarApi?.getDate();

    let tempParams = Object.assign(navigationParams);
    if (views[title] === 30) {
      if (val === "subtract") {
        currentDate.setDate(currentDate.getDate() - views[title]);
      }
      if (val === "add") {
        currentDate.setDate(currentDate.getDate() + views[title]);
      }
    } else {
      if (val === "subtract") {
        currentDate.setDate(currentDate.getDate() - views[title]);
      }
      if (val === "add") {
        currentDate.setDate(currentDate.getDate() + views[title]);
      }
    }
    setToday(currentDate);
    tempParams.viewType = title;
    tempParams.numOfDays = views[title];
    tempParams.availableRooms = selectedRoom?.includes("availableRooms");
    tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
    tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
    tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
    tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
    tempParams.startDate = moment(currentDate)
      .set({ hour: 0, minute: 0, second: 0 })
      .format(dateFormat);
    const temporary = [];
    if (selectedRoom.some((e) => e.id === "All") || selectedRoom.length === 0) {
      tempParams.roomTypes = rooms.forEach((e) => {
        if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
      });
    } else {
      selectedRoom.forEach((room) => {
        temporary.push(room.id);
      });
      tempParams.roomTypes = temporary;
    }
    tempParams.clusterRooms = sessionStorage.getItem("showRoomName")
      ? sessionStorage.getItem("showRoomName")
      : false;
    return tempParams;
  };

  const getParamsForViewForGroup = (val) => {
    let title = calendarApi?.view?.type;

    let currentDate = calendarApi?.getDate();

    let tempParams = Object.assign(navigationParams);
    if (views[title] === 30) {
      if (val === "subtract") {
        currentDate.setDate(currentDate.getDate() - views[title]);
      }
      if (val === "add") {
        currentDate.setDate(currentDate.getDate() + views[title]);
      }
    } else {
      if (val === "subtract") {
        currentDate.setDate(currentDate.getDate() - views[title]);
      }
      if (val === "add") {
        currentDate.setDate(currentDate.getDate() + views[title]);
      }
    }

    tempParams.viewType = title;
    tempParams.numOfDays = views[title];
    tempParams.availableRooms = selectedRoom?.includes("availableRooms");
    tempParams.blockedRooms = selectedRoom?.includes("blockedRooms");
    tempParams.dirtyRooms = selectedRoom?.includes("dirtyRooms");
    tempParams.cleanRooms = selectedRoom?.includes("cleanRooms");
    tempParams.bookedRooms = selectedRoom?.includes("bookedRooms");
    tempParams.startDate = moment(currentDate)
      .set({ hour: 0, minute: 0, second: 0 })
      .format(dateFormat);
    const temporary = [];
    if (selectedRoom.some((e) => e.id === "All") || selectedRoom.length === 0) {
      tempParams.roomTypes = rooms.forEach((e) => {
        if (e.id !== "All" && e.id !== "Unselect") temporary.push(e.id);
      });
    } else {
      selectedRoom.forEach((room) => {
        temporary.push(room.id);
      });
      tempParams.roomTypes = temporary;
    }
    tempParams.clusterRooms = sessionStorage.getItem("showRoomName")
      ? sessionStorage.getItem("showRoomName")
      : false;
    return tempParams;
  };

  const getRoomsData = async () => {
    const resp = await getAllRoomTypessWithHotelId(hotelId);
    const temp_rooms = [];

    for (const [key, value] of Object.entries(resp)) {
      rooms.push({ text: value, id: key });
    }
    setRooms(rooms);
    setSelectedRoom(rooms.filter((e) => e.text === "All"));
  };
  // To detect the changes in the calendar and re-rendering
  useEffect(() => {
    if (calendarApi === null) {
      getRoomsData();
      fetchBookingData();
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [calendarApi]);

  const getSource = (source) => {
    if (source !== undefined) {
      if (channelLogoMap[source] === undefined) return "";
      return channelLogoMap[source];
    }
  };

  useEffect(() => {
    if (
      calendarApi !== null &&
      calendarApi !== undefined &&
      calendarApi !== ""
    ) {
      calendarApi.changeView("resourceTimelineWeek");
      let tempData = getParamsForView("week");
      fetchBookingDataWithParams(tempData, "week");
    }
    setScrollPosition(window.pageYOffset);
  }, [sessionStorage.getItem("types"), calendarApi]);

  // const [checked, setChecked] = useState(false);

  const handleChangeName = (event) => {
    // setChecked(event.target.checked);
    if (event.target.checked) {
      let tempData = getParamsForView(sessionStorage.getItem("types"));
      tempData.clusterRooms = true;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    } else {
      let tempData = getParamsForView(sessionStorage.getItem("types"));
      tempData.clusterRooms = false;
      fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
    }

    setshowRoomName(event.target.checked);
    sessionStorage.setItem("showRoomName", event.target.checked);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("showRoomName") === undefined ||
      sessionStorage.getItem("showRoomName") === null ||
      sessionStorage.getItem("showRoomName") === "" ||
      sessionStorage.getItem("types") === undefined ||
      sessionStorage.getItem("types") === null ||
      sessionStorage.getItem("types") === ""
    ) {
      setshowRoomName(false);
    } else {
      const data =
        sessionStorage.getItem("showRoomName") === "true" ? true : false;

      if (
        calendarApi !== undefined &&
        calendarApi !== null &&
        calendarApi !== ""
      ) {
        if (data) {
          let tempData = getParamsForView(sessionStorage.getItem("types"));
          tempData.clusterRooms = true;
          fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
        } else {
          let tempData = getParamsForView(sessionStorage.getItem("types"));
          tempData.clusterRooms = false;
          fetchBookingDataWithParams(tempData, sessionStorage.getItem("types"));
        }
      }

      setshowRoomName(data);
    }
    setScrollPosition(window.pageYOffset);
  }, [
    sessionStorage.getItem("showRoomName"),
    sessionStorage.getItem("types"),
    calendarApi,
  ]);

  // Payment Flows
  const loggedUserEmail = localStorage.getItem("email");
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(reservationData.total_amount_with_services).toFixed(2) > 100000
    ) {
      toast.error(
        `${t("Cannot take cash transaction of more than 100000 in cash")}`
      );
    } else {
      if (hotelType === "ISHA") {
        toast.error(
          `${t(
            "Please ensure to preview the E-Receipt before accepting the payment"
          )}`
        );
      }
      setOpenCashPayment(true);
    }
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);
  const [amount, setAmount] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);
  const [offlineCardAmt, setOfflineCardAmt] = useState(
    Number(reservationData.balance_due).toFixed(2)
  );
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();
  const [serviceAmountDesc, setServiceAmountDesc] = useState();
  const [offlineCardDesc, setOfflineCardDesc] = useState();
  const handleCloseCashPayment = () => setOpenCashPayment(false);
  const currency = hotelCurrency;

  const handleCashPayment = () => {
    setCashPayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotel.hotelId,
          bookingID: reservationData.booking_id,
          reservation_id: "",
          amount: amount,
          offline_card_date: handleSentDateFormat(offlineCashDate),
          service_amount_desc: serviceAmountDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(`${t("Cash payment of")} ${amount} ${t("recorded")}`)
      )
      .then((res) => setCashPayLoading(false))
      .then((res) => setAmount(""))
      .then((res) => setServiceAmountDesc())
      .then((res) => handleCloseCashPayment())
      .catch((error) => setCashPayLoading(false))
      .catch((error) => handleCloseCashPayment())
      .catch((error) => toast.error(error));
  };

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(reservationData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = () => {
    setUpiPayLoading(true);
    axios
      .post(`${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`, {
        hotelId: hotel?.hotelId,
        bookingID: reservationData?.booking_id,
        reservation_id: "",
        amount: Number(upiAmt),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc: upiDesc,
        logged_user_email: loggedUserEmail,
      })
      // .then((res) => updateSummaryDataFromCache())
      .then((res) =>
        toast.success(
          `${t("UPI payment of")} ${upiAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setUpiPayLoading(false))
      .then((res) => setUpiAmt(""))
      .then((res) => setUpiDate(new Date()))
      .then((res) => setUpiDesc(""))
      .then((res) => handleCloseUpiPay())
      .catch((err) => toast.error(err))
      .then((err) => setUpiPayLoading(false))
      .then((err) => handleCloseUpiPay());
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);

  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    reservationData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(
    reservationData?.balance_due
  );
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleBankTransferPayment = () => {
    setBankTransferModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: bankTransferModeAmt,
          bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
          service_amount_desc: bankTransferModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Bank transfer payment of")} ${bankTransferModeAmt} ${t(
            "recorded for"
          )} ${reservationData?.booking_id}!`
        )
      )
      .catch((err) => toast.error(err))
      .finally(() => setBankTransferModeAmt(""))
      .finally(() => setBankTransferModeDate(new Date()))
      .finally(() => setBankTransferModeDesc(""))
      .finally(() => handleCloseBankTransferModePay())
      .finally(() => setBankTransferModePayLoading(false));
  };

  const handleOtherModePayment = () => {
    setOtherModePayLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotel?.hotelId,
          bookingID: reservationData?.booking_id,
          reservation_id: "",
          amount: otherModeAmt,
          other_payment_date: handleSentDateFormat(otherModeDate),
          service_amount_desc: otherModeDesc,
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) =>
        toast.success(
          `${t("Other payment of")} ${otherModeAmt} ${t("recorded for")} ${
            reservationData?.booking_id
          }!`
        )
      )

      .then((res) => setOtherModePayLoading(false))
      .then((res) => setOtherModeAmt(""))
      .then((res) => setOtherModeDate(new Date()))
      .then((res) => setOtherModeDesc(""))
      .then((res) => handleCloseOtherModePay())
      .catch((err) => setOtherModePayLoading(false))
      .catch((err) => handleCloseOtherModePay())
      .catch((err) => toast.error(err));
  };

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);
  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };
  // deposit loading

  const [myCounter, setmyCounter] = useState(0);

  useEffect(() => {
    if (calendarApi && today && myCounter === 0) {
      calendarApi.gotoDate(today);
      let tempData = getParamsForView("date");
      fetchBookingDataWithParams(tempData);
      setmyCounter(1);
    }
  }, [today, calendarApi]);

  return (
    <>
      <Grid
        container
        sx={{ "&:lastChild": 3 }}
        width="100%"
        height="100%"
        spacing={2}
      >
        {/* <Grid item container xs={12} spacing={2}>
          <Grid
            item
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            alignSelf="center"
            justifyContent="flex-start"
            container
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                color="primary"
                sx={{ overflow: "auto" }}
                style={{ border: "none" }}
                onClick={() => {
                  let tempData = getParamsForView("subtract");
                  fetchBookingDataWithParams(tempData);
                  calendarApi.prev();
                  setShowUnassigned(false);
                }}
              >
                <ArrowBackIosNew />
              </IconButton>
            </Grid>
          </Grid>

          <Modal show={open} onHide={handleClose} style={{ zIndex: "3000" }}>
            <Modal.Header closeButton>
              <Modal.Title>
                {`${dirtyRoomId} ${t("Room is Dirty")}`}{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{t("Do you want to clean the room?")}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("Close")}
              </Button>
              <Button variant="custom-button" onClick={handleCleanRoom}>
                {t("Clean room")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Grid item container xs={8} sm={8} md={7} lg={7} xl={7} spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              container
              justifyContent="center"
              alignSelf="center"
              style={{ marginLeft: "6rem" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={today}
                  onChange={(newValue) => {
                    setToday(newValue);
                    calendarApi.gotoDate(newValue);
                    let tempData = getParamsForView("date");
                    fetchBookingDataWithParams(tempData);
                    setShowUnassigned(false);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h5">
                        {checkForEmptyCalendar()
                          ? calendarApi.view.title
                          : moment(new Date()).format("MMMM D, YYYY")}
                      </Typography>

                      <div ref={inputRef} {...inputProps}>
                        {InputProps?.endAdornment}
                      </div>
                    </Box>
                  )}
                ></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            item
            xs={2}
            sm={2}
            md={2}
            lg={4}
            xl={4}
            alignSelf="center"
            justifyContent="flex-end"
            container
          >
            <Grid item container justifyContent="flex-end" xs={12}>
              <IconButton
                color="primary"
                onClick={() => {
                  let tempData = getParamsForView("add");
                  fetchBookingDataWithParams(tempData);
                  calendarApi.next();
                  setShowUnassigned(false);
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item display={"inline-flex"} xs={12} height="100%">
          {loading && (
            <Grid
              item
              xs={12}
              position={"fixed"}
              top="50%"
              left="50%"
              container
            >
              <DimmingDots />
            </Grid>
          )}

          <div
            style={{ width: showUnassigned ? "70%" : "100%" }}
            className="demo-app-main"
          >
            <FullCalendar
              // nowIndicator={true}
              refetchResourcesOnNavigate={true}
              eventResizableFromStart={true}
              resourceAreaWidth={100}
              schedulerLicenseKey="0974064232-fcs-1639721704"
              selectOverlap={slotType === "PURE_HOURLY"}
              views={{
                resourceTimelineWeek: {
                  nowIndicator: true,
                  titleFormat: { month: "short", day: "2-digit" },
                  contentHeight: "auto",
                  height: "auto",
                  selectOverlap: slotType === "PURE_HOURLY",
                  slotLabelDidMount: (el) => {
                    if (
                      el.level === 1 ||
                      el.text === "12am" ||
                      el.text === "8am" ||
                      el.text === "4pm"
                    ) {
                      el.el.className = el.el.className + " dont-display";
                    }
                    if (el.level === 0) {
                      el.el.className = el.el.className + " special-display";
                      el.el.childNodes[0].className =
                        el.el.childNodes[0].className + " spe-height";
                    }
                  },
                  slotLabelWillUnmount: (el) => {
                    if (
                      el.level === 1 ||
                      el.text === "12am" ||
                      el.text === "8am" ||
                      el.text === "4pm"
                    ) {
                      el.el.className = el.el.className + " dont-display";
                    }
                    if (el.level === 0) {
                      el.el.className = el.el.className + " special-display";
                      el.el.childNodes[0].className =
                        el.el.childNodes[0].className + " spe-height";
                    }
                  },
                  slotMinTime: "00:00:00",
                  slotMaxTime: "24:00:00",
                  slotDuration: slotType === "NOON" ? Noonslot : hotelSlot,
                  //   ? "23:00:00" : hourToString[initialData.checkInTimes[initialData.checkInTimes.length - 1]].text,
                  slotLabelContent: (day) => {
                    if (day.level === 0) {
                      return (
                        <span>
                          <Tooltip
                            arrow
                            title={`Go to ${day.date.toDateString()}`}
                          >
                            <Typography
                              onClick={(e) => {
                                e.stopPropagation();
                                calendarApi.changeView("resourceTimelineDay");
                                setToday(day.date);
                                calendarApi.gotoDate(day.date);
                                let tempData = getParamsForView("date");
                                fetchBookingDataWithParams(tempData);
                              }}
                              sx={{
                                color: "#2C3E50 !important",
                                display: "flex",
                                paddingRight: "4px",
                                fontWeight: 600,
                              }}
                              component={"div"}
                              color={"inherit"}
                            >
                              <span className="d-flex flex-column">
                                <span>{day.date.toDateString()}</span>
                                <span>
                                  {`${
                                    occupancyData &&
                                    Object.entries(occupancyData).length > 0 &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).occRatio &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).occRatio !== "undefined" &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).occRatio !== undefined &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).occRatio !== null &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).occRatio
                                  }`}
                                </span>
                                <span>
                                  {`${currency} ${
                                    occupancyData &&
                                    Object.entries(occupancyData).length > 0 &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).price &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).price !== "undefined" &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).price !== undefined &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).price !== null &&
                                    getHotelDetails(
                                      hotelId,
                                      day.date.toDateString()
                                    ).price
                                  }`}
                                </span>
                              </span>
                            </Typography>
                          </Tooltip>
                        </span>
                      );
                    } else {
                      return (
                        <Typography sx={{ color: "#2C3E50 !important" }}>
                          {day.text}
                        </Typography>
                      );
                    }
                  },
                  eventDidMount: (eve) => {
                    if (
                      eve.event.extendedProps.status === "CONFIRMED" ||
                      eve.event.extendedProps.status === "ADMIN_CONFIRMED"
                    ) {
                      var div = document.createElement("div");
                      div.className = "fc-event-resizer fc-event-resizer-start";
                      var div1 = document.createElement("div");
                      div.className = "fc-event-resizer fc-event-resizer-end";
                      eve.el.appendChild(div);
                      eve.el.appendChild(div1);
                    }
                  },
                  eventContent: (arg) => {
                    const start = moment(
                      arg.event._instance.range.start
                    ).format("DD-MM-YYYY");
                    const end = moment(arg.event._instance.range.end).format(
                      "DD-MM-YYYY"
                    );

                    let days = moment(arg.event._instance.range.end).diff(
                      moment(arg.event._instance.range.start),
                      "days"
                    );

                    let len = arg.event.title.length;
                    let finalTital;
                    if (days === 0 && len > 10) {
                      finalTital = arg.event.title.substring(0, 6) + "...";
                    } else if (days === 1 && len > 30) {
                      finalTital = arg.event.title.substring(0, 25) + "...";
                    } else if (days === 2 && len > 45) {
                      finalTital = arg.event.title.substring(0, 40) + "...";
                    } else {
                      finalTital = arg.event.title;
                    }
                    return (
                      <div>
                        <div>
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontWeight: 600,
                              fontSize: "1rem",
                              paddingLeft: "1rem",
                              color: "#2C3F51",
                            }}
                          >
                            {arg.event.extendedProps.locked_status ===
                              "LOCKED" && (
                              <Lock
                                color={
                                  arg.event.extendedProps.status ===
                                    "CHECKED_OUT" ||
                                  arg.event.extendedProps.status ===
                                    "CONFIRMED" ||
                                  arg.event.extendedProps.status === "DONE"
                                    ? "primary"
                                    : "inherit"
                                }
                                sx={{ marginRight: 1 }}
                              />
                            )}
                            {/* {arg.event.title} */}
                            {finalTital}

                            {whiteLabelled === "ENABLED"
                              ? getSource(
                                  arg.event.extendedProps.bookingSource
                                )?.includes(".png") &&
                                !whitlableArrMap.includes(
                                  arg.event.extendedProps.bookingSource
                                ) && (
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                )
                              : getSource(
                                  arg.event.extendedProps.bookingSource
                                )?.includes(".png") && (
                                  <img
                                    src={getSource(
                                      arg.event.extendedProps.bookingSource
                                    )}
                                    style={{
                                      height: "2rem",
                                      marginLeft: "10px",
                                      borderRadius: "100%",
                                    }}
                                  />
                                )}
                            {arg.event.extendedProps.balanceDue > 0 && (
                              <BalanceIcon
                                style={{
                                  marginLeft: "10px",
                                  color: arg.event.extendedProps
                                    .highBalanceDueAlertStatus
                                    ? "red"
                                    : "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.bookingNotes === "YES" && (
                              <SpeakerNotesIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                            {arg.event.extendedProps.groupBooking === true && (
                              <GroupsIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}

                            {arg.event.extendedProps.selfServiceStatus ===
                              "REQUESTED" && (
                              <RadioButtonUncheckedIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}

                            {arg.event.extendedProps.selfServiceStatus ===
                              "APPROVED" && (
                              <CheckCircleIcon
                                style={{
                                  marginLeft: "10px",
                                  color: "darkcyan",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {/* </LightTooltip> */}
                      </div>
                    );
                  },
                },
                resourceTimelineDay: {
                  nowIndicator: true,
                  titleFormat: { month: "short", day: "2-digit" },
                  contentHeight: "auto",
                  height: "auto",
                  selectOverlap: false,
                  slotDuration: "01:00:00",
                  slotLabelContent: (data) => {
                    return (
                      <Typography
                        sx={{ color: "#2C3E50 !important", fontWeight: 600 }}
                      >
                        {data.text}
                      </Typography>
                    );
                  },
                  slotLaneContent: (day) => {
                    return (
                      <div>
                        <Typography>{day.time}</Typography>
                      </div>
                    );
                  },
                  eventContent: (arg) => {
                    return (
                      <div>
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontWeight: 600,
                            fontSize: "1rem",
                            paddingLeft: "1rem",
                            color: "#2C3F51",
                          }}
                        >
                          {arg.event.extendedProps.locked_status ===
                            "LOCKED" && (
                            <Lock
                              color={
                                arg.event.extendedProps.status ===
                                  "CHECKED_OUT" ||
                                arg.event.extendedProps.status ===
                                  "CONFIRMED" ||
                                arg.event.extendedProps.status === "DONE"
                                  ? "primary"
                                  : "inherit"
                              }
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          {arg.event.title}

                          {whiteLabelled === "ENABLED"
                            ? getSource(
                                arg.event.extendedProps.bookingSource
                              )?.includes(".png") &&
                              !whitlableArrMap.includes(
                                arg.event.extendedProps.bookingSource
                              ) && (
                                <img
                                  src={getSource(
                                    arg.event.extendedProps.bookingSource
                                  )}
                                  style={{
                                    height: "2rem",
                                    marginLeft: "10px",
                                    borderRadius: "100%",
                                  }}
                                />
                              )
                            : getSource(
                                arg.event.extendedProps.bookingSource
                              )?.includes(".png") && (
                                <img
                                  src={getSource(
                                    arg.event.extendedProps.bookingSource
                                  )}
                                  style={{
                                    height: "2rem",
                                    marginLeft: "10px",
                                    borderRadius: "100%",
                                  }}
                                />
                              )}
                          {arg.event.extendedProps.balanceDue > 0 && (
                            <BalanceIcon
                              style={{
                                marginLeft: "10px",
                                color: arg.event.extendedProps
                                  .highBalanceDueAlertStatus
                                  ? "red"
                                  : "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.bookingNotes === "YES" && (
                            <SpeakerNotesIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.groupBooking === true && (
                            <GroupsIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}

                          {arg.event.extendedProps.selfServiceStatus ===
                            "REQUESTED" && (
                            <RadioButtonUncheckedIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.selfServiceStatus ===
                            "APPROVED" && (
                            <CheckCircleIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      // </LightTooltip>
                    );
                  },
                },
                resourceTimelineMonthCustom: {
                  nowIndicator: true,
                  titleFormat: { month: "short", day: "2-digit" },
                  contentHeight: "auto",
                  height: "auto",
                  type: "resourceTimeline",
                  selectOverlap: slotType === "PURE_HOURLY",
                  eventMaxStack: 2,
                  dayCount: 30,
                  duration: { days: 30 },
                  slotLabelContent: (day) => {
                    return (
                      <div style={{ width: "100%" }}>
                        {day.level === 0 && (
                          <span style={{ display: "flex" }}>
                            <Tooltip
                              title={`Go to ${day.date.toDateString()}`}
                              arrow
                            >
                              <Typography
                                onClick={(e) => {
                                  e.stopPropagation();
                                  calendarApi.changeView("resourceTimelineDay");
                                  setToday(day.date);
                                  calendarApi.gotoDate(day.date);
                                  let tempData = getParamsForView("date");
                                  fetchBookingDataWithParams(tempData);
                                }}
                                sx={{
                                  color: "#2C3E50 !important",
                                  display: "flex",
                                  paddingRight: "4px",
                                  fontWeight: 600,
                                }}
                                component={"div"}
                              >
                                <span className="d-flex flex-column">
                                  <span>{day.text}</span>
                                  <span>
                                    {`${
                                      occupancyData &&
                                      Object.entries(occupancyData).length >
                                        0 &&
                                      getHotelDetails(hotelId, day.text)
                                        .occRatio &&
                                      getHotelDetails(hotelId, day.text)
                                        .occRatio !== "undefined" &&
                                      getHotelDetails(hotelId, day.text)
                                        .occRatio !== undefined &&
                                      getHotelDetails(hotelId, day.text)
                                        .occRatio !== null &&
                                      getHotelDetails(hotelId, day.text)
                                        .occRatio
                                    }`}
                                  </span>
                                  <span>
                                    {`${currency} ${
                                      occupancyData &&
                                      Object.entries(occupancyData).length >
                                        0 &&
                                      getHotelDetails(hotelId, day.text)
                                        .price &&
                                      getHotelDetails(hotelId, day.text)
                                        .price !== "undefined" &&
                                      getHotelDetails(hotelId, day.text)
                                        .price !== undefined &&
                                      getHotelDetails(hotelId, day.text)
                                        .price !== null &&
                                      getHotelDetails(hotelId, day.text).price
                                    }`}
                                  </span>
                                </span>
                              </Typography>
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    );
                  },
                  eventContent: (arg) => {
                    return (
                      <div>
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontWeight: 600,
                            fontSize: "1rem",
                            paddingLeft: "1rem",
                            color: "#2C3F51",
                          }}
                        >
                          {arg.event.extendedProps.locked_status ===
                            "LOCKED" && (
                            <Lock
                              color={
                                arg.event.extendedProps.status ===
                                  "CHECKED_OUT" ||
                                arg.event.extendedProps.status ===
                                  "CONFIRMED" ||
                                arg.event.extendedProps.status === "DONE"
                                  ? "primary"
                                  : "inherit"
                              }
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          {arg.event.title}

                          {whiteLabelled === "ENABLED"
                            ? getSource(
                                arg.event.extendedProps.bookingSource
                              )?.includes(".png") &&
                              !whitlableArrMap.includes(
                                arg.event.extendedProps.bookingSource
                              ) && (
                                <img
                                  src={getSource(
                                    arg.event.extendedProps.bookingSource
                                  )}
                                  style={{
                                    height: "2rem",
                                    marginLeft: "10px",
                                    borderRadius: "100%",
                                  }}
                                />
                              )
                            : getSource(
                                arg.event.extendedProps.bookingSource
                              )?.includes(".png") && (
                                <img
                                  src={getSource(
                                    arg.event.extendedProps.bookingSource
                                  )}
                                  style={{
                                    height: "2rem",
                                    marginLeft: "10px",
                                    borderRadius: "100%",
                                  }}
                                />
                              )}
                          {arg.event.extendedProps.balanceDue > 0 && (
                            <BalanceIcon
                              style={{
                                marginLeft: "10px",
                                color: arg.event.extendedProps
                                  .highBalanceDueAlertStatus
                                  ? "red"
                                  : "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.bookingNotes === "YES" && (
                            <SpeakerNotesIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.groupBooking === true && (
                            <GroupsIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.selfServiceStatus ===
                            "REQUESTED" && (
                            <RadioButtonUncheckedIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                          {arg.event.extendedProps.selfServiceStatus ===
                            "APPROVED" && (
                            <CheckCircleIcon
                              style={{
                                marginLeft: "10px",
                                color: "darkcyan",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      // </LightTooltip>
                    );
                  },
                },
              }}
              droppable={false}
              contentHeight={"auto"}
              height="auto"
              scrollTime={"00:00"}
              forceEventDuration={true}
              firstDay={
                checkForEmptyCalendar()
                  ? calendarApi.getDate().getDay()
                  : new Date().getDay()
              }
              lazyFetching={true}
              plugins={[interactionPlugin, resourceTimelinePlugin]}
              headerToolbar={false}
              resourceLabelContent={(arg) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      color: `${
                        showRoomName
                          ? arg.resource.extendedProps.roomTypeColor ===
                            "#ffffff"
                            ? "black"
                            : arg.resource.extendedProps.roomTypeColor
                          : "black"
                      }`,
                    }}
                    onClick={(e) => {
                      setdirtyRoomId(arg.resource.title);
                      if (
                        arg.resource.extendedProps.dirtyColor === "bg-danger"
                      ) {
                        handleOpen();
                      }
                    }}
                  >
                    <div
                      style={{
                        width: `${
                          arg.resource.extendedProps.dirtyColor === "bg-danger"
                            ? "1em"
                            : "1rem"
                        }`,
                        marginRight: "10px",
                      }}
                    >
                      {arg.resource.extendedProps.dirtyColor ===
                        "bg-danger" && (
                        // !(
                        //   arg.resource.extendedProps.dirtyColor !== undefined ||
                        //   arg.resource.extendedProps.dirtyColor !== null
                        // )
                        <FiberManualRecord
                          color="error"
                          fontSize="small"
                          sx={{ ml: 0.5, alignSelf: "center" }}
                        />
                      )}
                    </div>

                    <LightTooltip
                      title={arg.resource.extendedProps.roomType}
                      arrow
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {arg.resource.title}
                      </Typography>
                    </LightTooltip>

                    {showRoomName && (
                      <LightTooltip
                        title={arg.resource.extendedProps.roomType}
                        arrow
                      >
                        <Typography sx={{ ml: 0.5, fontWeight: "bold" }}>
                          {` - ${arg.resource.extendedProps.roomTypeCode}`}
                        </Typography>
                      </LightTooltip>
                    )}
                  </div>
                );
              }}
              eventResizeStart={() => {
                setActionType("extend");
              }}
              eventDragStart={() => {
                setActionType("DragorDrop");
              }}
              initialView={
                slotType === "PURE_HOURLY"
                  ? "resourceTimelineDay"
                  : "resourceTimelineWeek"
              }
              initialDate={
                currency === "INR"
                  ? new Date().toISOString()
                  : new Date(Date.now() - 3600 * 1000 * 24).toISOString()
              }
              selectable={true}
              events={initialData.bookingData}
              eventClick={handleDateSelect}
              resources={roomNumbers}
              resourceOrder={function (a, b) {
                return parseInt(a.initalId) < parseInt(b.initalId)
                  ? -1
                  : a.initalId > b.initalId
                  ? 1
                  : 0;
              }}
              stickyHeaderDates={"auto"}
              eventOverlap={true}
              select={addNew}
              ref={calendarRef}
              eventChange={handleDragStop}
              resourceAreaHeaderContent={"Rooms"}
              eventClassNames={(arg) => {
                if (arg.event.extendedProps.enquiry === "true") {
                  return ["fc-enquiry-event"];
                } else {
                  if (arg.event.extendedProps.status === "CONFIRMED") {
                    return ["fc-confirmed-event"];
                  }

                  if (arg.event.extendedProps.status === "CHECKED_OUT") {
                    return ["fc-checkedout-event"];
                  }

                  if (arg.event.extendedProps.status === "DONE") {
                    return ["fc-checkedout-event"];
                  }
                  if (arg.event.extendedProps.status === "BLOCKED")
                    return ["fc-blocked-event"];

                  if (arg.event.extendedProps.status === "COMBO")
                    return ["fc-combo-room"];
                  return ["timeline-calendar"];
                }
              }}
            />
            <div></div>
            {show && actionType === "add" && showModal.resource ? (
              <GroupModalView
                open={show}
                viewType={calendarApi.view.type}
                reload={fetchBookingDataWithoutLoad}
                reloadagain={reloadData}
                calendarApi={calendarApi}
                eventType={actionType}
                eventData={showModal}
                handleAddEventSidebar={handleAddEventSidebar}
                addEvent={setNewData}
                roomInformation={
                  initialData.roomTypeInformation[
                    showModal?.resource?.extendedProps?.roomTypeId.split(",")[0]
                  ]
                }
                hotelId={hotelId}
                hotelSlotType={slotType}
                hotelCurrency={hotelCurrency}
                currency={hotelCountry}
              />
            ) : null}
            {show && actionType === "DragorDrop" ? (
              <GroupDragModalView
                viewType={calendarApi.view.type}
                reload={fetchBookingDataWithoutLoad}
                calendarApi={calendarApi}
                eventType={actionType}
                eventData={showModal}
                open={show}
                handleAddEventSidebar={modify}
                addEvent={changeDateTime}
                moveEvent={moveToNewRoom}
                roomInformation={originalAllRoomInfo}
                roomTypeInformation={initialData.roomTypeInformation}
                edit={"edit"}
                hotelId={hotelId}
                hotelSlotType={slotType}
                hotelCurrency={hotelCurrency}
                currency={hotelCurrency}
              ></GroupDragModalView>
            ) : null}
            {show && actionType === "extend" ? (
              <GroupExtendModalView
                viewType={calendarApi.view.type}
                eventData={showModal}
                open={show}
                handleAddEventSidebar={modify}
                addEvent={changeDateTime}
                reload={fetchBookingDataWithoutLoad}
                hotelId={hotelId}
                hotelSlotType={slotType}
                hotelCurrency={hotelCurrency}
                currency={hotelCountry}
              ></GroupExtendModalView>
            ) : null}
            {show &&
            actionType === "edit" &&
            showModal?.event.extendedProps.reservationId != "BLK" ? (
              <GroupEditModalView
                viewType={calendarApi.view.type}
                reload={fetchBookingDataWithoutLoad}
                lockEvent={lockEvent}
                removeEvent={removeEvent}
                calendarApi={calendarApi}
                eventType={actionType}
                eventData={showModal}
                open={show}
                hotelCountry={hotelCountry}
                // viewFolio={(one, two, three) => {
                //   toggleDrawer(one, two, three);
                // }}
                handleAddEventSidebar={handleAddEventSidebar}
                addEvent={handleAddEventSidebar}
                roomInformation={originalAllRoomInfo}
                roomTypeInfo={initialData.roomTypeInformation}
                hotelId={hotelId}
                hotelSlotType={slotType}
                hotelCurrency={hotelCurrency}
              ></GroupEditModalView>
            ) : null}

            {/* <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
            }}
          >
            {folioDrawer("right")}
          </Drawer> */}
          </div>

          {showUnassigned && (
            <div
              style={{
                width: "30%",
                maxHeight: "100%",
                padding: "0px 16px 16px 16px",
              }}
            >
              <Slide in>
                <UnassignedView
                  data={initialData}
                  date={unassignedDate}
                  closeFunc={() => {
                    setShowUnassigned(false);
                  }}
                  reload={fetchBookingDataWithoutLoad}
                  selectedLanguage={selectedLanguage}
                ></UnassignedView>
              </Slide>
            </div>
          )}
          {/* <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={snackbar}
        autoHideDuration={6000} onClose={() => {
          setSnackbar(false)
        }}>
        <Alert onClose={() => {
          setSnackbar(false)
        }} severity="success">
          Booking Successful
        </Alert>
      </Snackbar> */}
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return { datass: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(NewGroupTimeLine);
